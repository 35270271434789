import { Badge, Col, Container, EmbeddedVideo, EmbeddedYouTube, PageBanner, Row, SocialButton, Text, Thumbnail, Title } from '@zigurous/react-components'; // prettier-ignore
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { DetailsList, ImageProps, Page } from '../../components';
import { trackEvent } from '../../firebase/analytics';

export const query = graphql`
  query ($id: String!) {
    tutorial: tutorialsJson(id: { eq: $id }) {
      badges
      description
      details {
        key
        url
        value
      }
      id: jsonId
      image {
        sharp: childImageSharp {
          original {
            src
            width
            height
          }
        }
      }
      subtitle
      title
      videoEmbedId
      videoPlaylistId
      videoThumbnail {
        sharp: childImageSharp {
          original {
            src
            width
            height
          }
        }
      }
      videoURL
    }
  }
`;

function Tutorial({ data, location }) {
  const { tutorial } = data;
  const metadata = {
    url: `https://zigurous.com/tutorials/${tutorial.id}`,
    title: `Zigurous • ${tutorial.title}`,
    description: tutorial.description,
    image: tutorial.image && tutorial.image.sharp.original.src,
  };
  return (
    <Page className="tutorial" location={location} metadata={metadata}>
      <PageBanner className="bg-surface-1">
        <Container fluid>
          <Row className="align-items-center">
            <Col lg={3}>
              <Title subtitle size={5}>
                {tutorial.subtitle}
              </Title>
              <Title tag="h1">{tutorial.title}</Title>
              {tutorial.badges && tutorial.badges.length > 0 && (
                <div className="margin-bottom-lg">
                  {tutorial.badges.map((badge) => (
                    <Badge key={badge} pill>
                      {badge}
                    </Badge>
                  ))}
                </div>
              )}
              {tutorial.videoURL && (
                <SocialButton
                  className="margin-top-md margin-bottom-xxl"
                  link="youtube"
                  onClick={() => {
                    trackEvent('social_button', {
                      content_type: 'button',
                      event_category: 'engagement',
                      event_label: 'Watch on YouTube',
                      item_id: tutorial.id,
                    });
                  }}
                  shape={SocialButton.shape.rounded}
                  size={SocialButton.size.small}
                  style={SocialButton.style.outline}
                  url={tutorial.videoURL}
                >
                  Watch on YouTube
                </SocialButton>
              )}
            </Col>
            <Col lg={9}>
              <Text size="lg">{tutorial.description}</Text>
            </Col>
          </Row>
        </Container>
      </PageBanner>
      <Container className="margin-top-xxxl" fluid>
        <Row>
          <Col className="margin-bottom-xl" xl={8}>
            {tutorial.videoEmbedId && (
              <EmbeddedYouTube
                origin="https://zigurous.com"
                videoId={tutorial.videoEmbedId}
              />
            )}
            {tutorial.videoPlaylistId && (
              <EmbeddedVideo
                className="youtube"
                id="youtube-player"
                src={`https://www.youtube.com/embed/videoseries?list=${tutorial.videoPlaylistId}`}
                title="YouTube"
              />
            )}
            {tutorial.videoThumbnail &&
              !tutorial.videoEmbedId &&
              !tutorial.videoPlaylistId && (
                <Thumbnail
                  alt={tutorial.title}
                  ElementType="span"
                  height={tutorial.videoThumbnail.sharp.original.height}
                  image={tutorial.videoThumbnail.sharp.original.src}
                  width={tutorial.videoThumbnail.sharp.original.width}
                />
              )}
          </Col>
          <Col className="margin-bottom-xl" xl={4}>
            <DetailsList
              details={tutorial.details}
              onLinkClick={(detail) => {
                trackEvent('external_link', {
                  content_type: 'link',
                  event_category: 'engagement',
                  event_label: detail.value,
                  item_id: tutorial.id,
                });
              }}
            />
          </Col>
        </Row>
      </Container>
    </Page>
  );
}

Tutorial.propTypes = {
  data: PropTypes.shape({
    tutorial: PropTypes.shape({
      badges: PropTypes.arrayOf(PropTypes.string),
      description: PropTypes.string.isRequired,
      details: PropTypes.arrayOf(DetailsList.itemProps),
      id: PropTypes.string.isRequired,
      image: ImageProps.isRequired,
      subtitle: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      videoEmbedId: PropTypes.string,
      videoPlaylistId: PropTypes.string,
      videoThumbnail: ImageProps,
      videoURL: PropTypes.string,
    }),
  }),
  location: PropTypes.object,
};

export default Tutorial;
